<template>
  <div>
    <vx-card actionable class="cardx" title="Net Enquiries Assignment">
      <vs-row type="flex" style="margin-top: 1%">
        <vs-col
          vs-order="1"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            v-model="filter"
            label="name"
            :reduce="(option) => option.value"
            :options="filters"
            style="width: 250px; z-index: 1000"
          ></v-select>
        </vs-col>
        <vs-col
          vs-order="1"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <treeselect
            v-model="initSelectedBDE"
            :multiple="true"
            :options="treeDataBDE"
            placeholder="CM TEAM"
            style="width: 240px; margin-right: 3%"
          />
        </vs-col>

        <vs-col
          vs-order="1"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <treeselect
            v-model="initSelectedIIML"
            :multiple="true"
            placeholder="IIML BA TEAM"
            :options="treeDataBDEIIML"
            style="width: 240px; margin-right: 3%"
          />
        </vs-col>
        <vs-col
          vs-order="2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range',
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            style="width: 200px"
            @update="analyzePagination(1)"
          ></date-range-picker>
          <vs-button
            style="margin-left: 10%"
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshnew"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-tabs style="margin-top: 1%">
        <vs-tab
          label="Paired Cities' Enquiries"
          @click="changeurl('getNetEnquiriesDBCities')"
        >
          <tabData
            v-if="showTable"
            :NetEnquiries="netEnquiries"
            :total="total"
            :rawBde="filterSpoc"
            :label="'paired'"
          ></tabData>
        </vs-tab>
        <vs-tab
          label="Unknown Cities"
          @click="changeurl('getNetEnquiriesOtherCities')"
        >
          <tabData
            v-if="showTable"
            :NetEnquiries="netEnquiries"
            :total="total"
            :rawBde="filterSpoc"
            :label="'unknown'"
          ></tabData>
        </vs-tab>
        <vs-tab
          label="International Enquiries"
          @click="changeurl('getInternationalNetEnquiries')"
        >
          <tabData
            v-if="showTable"
            :NetEnquiries="netEnquiries"
            :total="total"
            :rawBde="filterSpoc"
            :label="'international'"
          ></tabData>
        </vs-tab>
      </vs-tabs>
      <vs-pagination
        style="margin-top: 2%"
        :total="totalpagination"
        v-model="currentx"
      ></vs-pagination>
    </vx-card>
    <NetEnquiryAssignmentPopup></NetEnquiryAssignmentPopup>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus.js";
import vSelect from "vue-select";
import tabData from "./NetEnquiriesTableComponent.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import NetEnquiryAssignmentPopup from "../components/pagesComponents/NetEnquiryAssignmentPopup.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import _ from "lodash";

export default {
  mounted() {
    EventBus.$emit("unloadplus", true);
    EventBus.$on("updateNetEnquiry", (payload) => {
      this.updateNetEnquiry(payload);
    });
    EventBus.$on("updateCompleteNetEnquiry", () => {
      this.fetchData(this.currentx);
    });
    EventBus.$on("clickedatachange", (payload) => {
      this.clickedEnquiry.mwb_id = payload.id;
      this.clickedEnquiry.person_type = payload.miles_type;
      this.clickedEnquiry.identity = payload.identity;
      this.clickedEnquiry.person_id = payload.person_id;
      this.clickedEnquiry.person_name = payload.person_name;
    });
    EventBus.$on("reload-netEnquiries-cm2", () => {
      this.fetchData(this.currentx);
      this.checked = [];
    });
    this.getSpocAdmin();
    this.getExed();
  },
  components: {
    NetEnquiryAssignmentPopup,
    "v-select": vSelect,
    DateRangePicker,
    Treeselect,
    tabData,
  },
  data() {
    return {
      open: false,
      showTable: false,
      checked: [],
      total: 0,
      dateRange: {},
      searchIn: {
        id: "",
        name: "",
      },
      rawBde: [],
      totalpagination: 1,
      currentx: 1,
      initSelectedBDE: [],
      rawcities: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataBDEIIML: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      rawcitiesIIML: [],
      initSelectedIIML: [],
      filterSpoc: [],
      netEnquiries: [],
      filter: "not_assigned",
      filters: [
        { name: "All", value: "all" },
        // { name: "Assigned", value: "assigned" },
        { name: "Unassigned", value: "not_assigned" },
        { name: "Missed Hot Pocket", value: "missed_hot_pocket" },
        { name: "Connected", value: "connected" },
        { name: "Matched", value: "matched" },
        { name: "Unmatched", value: "unmatched" },
        { name: "Invalid", value: "invalid_net_enquiry" },
        { name: "Spam", value: "spam" },
      ],
      clickedEnquiry: {},
      spoc_names: [],
      role: localStorage.getItem("role"),
      url: "getNetEnquiriesDBCities",
      rawBdeIIML: [],
    };
  },
  watch: {
    currentx: function(value) {
      this.fetchData(value);
    },
    filter() {
      this.analyzePagination();
    },
    initSelectedBDE() {
      this.analyzePagination();
    },
    initSelectedIIML() {
      this.analyzePagination();
    },
  },
  methods: {
    changeurl(endpoint) {
      this.url = endpoint;
      this.analyzePagination();
    },
    isChat(details) {
      try {
        JSON.parse(details);
      } catch (e) {
        return false;
      }
      return true;
    },
    analyzePagination() {
      if (this.currentx === 1) {
        this.fetchData(1);
      } else {
        this.currentx = 1;
      }
    },
    assignLead() {
      EventBus.$emit("open-assign-net-enquiry-popup", this.checked);
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    calluntracked(tr) {
      console.log(tr);
      let params = {};
      params.phone_number = parseInt(tr.mobile);
      axios
        .get(constants.ADMIN_SERVER_API + "clickToCallUntracked", {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.notify({
            title: "Call Initialized",
            text: response.data.message,
            color: "success",
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    sortText(givenName) {
      switch (givenName) {
        case "missed_hot_pocket":
          return "MHP";
        case "connected":
          return "Connected";
        default:
          return givenName;
      }
    },
    getSpocName(id) {
      let name = "";
      this.rawBde.forEach((spoc) => {
        if (spoc.id === id) {
          name = spoc.first_name;
        }
      });
      return name;
    },

    getExed() {
      let url = `${constants.ADMIN_SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.searchIn.id = response.data.logged_in_user_id;
          // this.searchIn.name = response.data.logged_in_user_name;
          this.rawBdeIIML = response.data.spocs;
          // this.filterSpoc = response.data.spocs;
          this.treeDataBDEIIML[0].children = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcitiesIIML.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDEIIML[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDEIIML.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.first_name,
                    label: server_spoc.first_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          this.treeDataBDEIIML[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDEIIML[0].children
          );
          // if (this.rawBde.indexOf(this.searchIn) !== -1) {
          //   this.initSelectedBDE.push(this.searchIn.name);
          // } else {
          //   // this.initSelectedBDE.push("All");
          // }
          // this.fetchData(this.currentx);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getSpocAdmin() {
      let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          this.filterSpoc = response.data.spocs;
          this.treeDataBDE[0].children = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          } else {
            // this.initSelectedBDE.push("All");
          }
          this.fetchData(this.currentx);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    showValidSpocs(spocs) {
      var spoclist = _.clone(spocs, true);
      // let wanted = [
      //   56,
      //   111,
      //   84,
      //   83,
      //   191,
      //   103,
      //   135,
      //   133,
      //   134,
      //   89,
      //   94,
      //   76,
      //   75,
      //   100,
      //   96,
      //   176,
      //   174,
      //   99,
      //   193,
      //   54,
      //   180
      // ];
      let wanted = [
        56,
        192,
        84,
        83,
        191,
        193,
        103,
        135,
        133,
        134,
        89,
        80,
        54,
        76,
        75,
        100,
        180,
        96,
        176,
        174,
        194,
        99,
      ];
      let filteredSpocs = spoclist.filter(function(el) {
        return wanted.indexOf(el.id) >= 0;
      });
      console.log("df", filteredSpocs);
      return filteredSpocs;
    },
    updateNetEnquiry(payload) {
      let obj = {
        net_enquiry_id: this.clickedEnquiry.id,
        person_id: this.clickedEnquiry.person_id,
        person_name: this.clickedEnquiry.person_name,
        identity: this.clickedEnquiry.identity,
        mwb_id: this.clickedEnquiry.mwb_id,
        person_type: this.clickedEnquiry.person_type,
        connection_status: payload.connection_status,
      };
      axios
        .post(`${constants.ADMIN_SERVER_API}updateNetEnquiry`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then(() => {
          this.fetchData(this.currentx);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getmatched(matched_value) {
      if (matched_value === 1) {
        return "YES";
      } else {
        return "NO";
      }
    },
    fetchData(page) {
      this.showTable = false;
      let obj = {
        page: page,
        filter: this.filter,
        spoc_ids: "",
        from_date: "",
        to_date: "",
      };
      let bde = [];
      this.initSelectedBDE.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              bde.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.treeDataBDE[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            bde.push(sort);
          }
        }
      });
      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      obj.spoc_ids = bdeid.join();

      let bdeIIML = [];
      this.initSelectedIIML.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDEIIML[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              bdeIIML.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.treeDataBDEIIML[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((acutal_spoc) => {
                bdeIIML.push(acutal_spoc.label);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            bdeIIML.push(sort);
          }
        }
      });
      let bdeidIIML = [];
      bdeIIML.forEach((spoc) => {
        this.rawBdeIIML.forEach((raw) => {
          if (raw.first_name === spoc) {
            bdeidIIML.push(raw.id);
          }
        });
      });
      obj.iiml_ids = bdeidIIML.join();

      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }
      console.log(obj);
      // return;
      axios
        .get(`${constants.ADMIN_SERVER_API}${this.url}`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.showTable = true;
          this.total = response.data.total;
          this.netEnquiries = response.data.data;
          this.totalpagination = response.data.last_page;
        })
        .catch((error) => {
          this.showTable = true;
          this.handleError(error);
        });
    },
    refreshnew() {
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      this.filter = "not_assigned";
      this.initSelectedBDE = [];
      // this.initSelectedBDEIIML = [];
      this.initSelectedIIML = [];
      this.analyzePagination();
    },
    openPopupEmit(details) {
      this.clickedEnquiry = details;
      this.$vs.loading();
      axios
        .get(`${constants.ADMIN_SERVER_API}getMwbLead?mwb_id=${details.mwb_id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          response.data.data.engagements = [];
          response.data.data.mobile = [];
          response.data.data.emails = [];
          this.$store.commit("MOUNT_MWB_LEADS", [response.data.data]);
          axios
            .get(
              `${constants.ADMIN_SERVER_API}getSingleMwbLead?can_id=${
                response.data.data.can_id
              }&person_id=${response.data.data.person_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              }
            )
            .then((response2) => {
              this.$store.commit("UPDATE_NEW_DATA", [
                response.data.data,
                response2.data.engagements,
                response2.data.mobile,
                response2.data.emails,
              ]);
              EventBus.$emit(
                "open-student-details-popup",
                this.$store.getters.getObjectMwbLead(response.data.data)
              );
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.handleError(error);
            });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    searchLead(tr) {
      this.clickedEnquiry = tr;
      EventBus.$emit("search-net-enquiry-lead", tr);
    },
    addLead(tr) {
      this.clickedEnquiry = tr;
      EventBus.$emit("open-add-lead-popup");
      EventBus.$emit("prefilled-netEnquiry", tr);
    },
  },
  beforeDestroy() {
    EventBus.$emit("unloadplus", false);
  },
};
</script>

<style></style>
