<template lang="html">
  <div class="centerx">
    <vs-popup title="CHAT HISTORY"  class="holamundo" :active.sync="open">
      <vs-row v-for="(msg, index) in chatDetails" :key="index">
      <vs-col vs-type="flex" :vs-justify="getAlign(msg)" vs-align="flex-start" vs-w="12">
        <div
          class="msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm"
          :class="{'bg-primary-gradient text-white': isThisAgent(msg), 'border border-solid border-grey-light bg-white': isThisVisitor(msg)}"
        >
          <span>{{ msg.text }}</span>
        </div>
      </vs-col>
    </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import eventbus from "../eventbus";
import ChatInterface from "./DashboardChatInterface.vue";
export default {
  components: {
    ChatInterface
  },
  mounted() {
    eventbus.$on("open-net-enquiries-chat-popup", payload => {
      this.chatDetails = JSON.parse(payload.details);
      this.open = true;
    });
  },
  data() {
    return {
      chatDetails: [],
      open: false
    };
  },
  methods: {
    getAlign(msg) {
      if (msg.user_type === "agent") {
        return "flex-end";
      } else {
        return "flex-start";
      }
    },
    isThisAgent(msg) {
      if (msg.user_type === "agent") {
        return true;
      } else {
        return false;
      }
    },
    isThisVisitor(msg) {
      if (msg.user_type === "visitor") {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>