<template lang="html">
  <div class="centerx">
    <vs-popup
      v-if="open"
      class="holamundo"
      :title="
        `Assign ${
          checkedEnquiries.length
        } ${EnquirySpelling} to a Spoc for cities ${cityString}`
      "
      :active.sync="open"
    >
      <vs-row type="flex" style="margin-top: 0%">
        <vs-col
          vs-order="1"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <v-select
            style="z-index: 1001; width: 250px; margin-bottom: 3%"
            v-model="selected_team"
            :options="['CM Team', 'IIML Team']"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row
        v-if="selected_team === 'CM Team'"
        type="flex"
        style="margin-top: 7%"
      >
        <vs-col
          vs-order="1"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="6"
        >
          <v-select
            style="z-index: 1000; position: fixed; width: 250px; margin-bottom: 3%"
            v-model="selected_city"
            :options="cities"
          ></v-select>
        </vs-col>
        <vs-col
          vs-order="2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="6"
        >
          <v-select
            style="z-index: 1000; position: fixed; width: 250px; margin-bottom: 3%"
            v-model="selected_spoc"
            :options="spocs"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row v-else type="flex" style="margin-top: 7%">
        <vs-col
          vs-order="1"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <v-select
            style="z-index: 1000; position: fixed; width: 250px; margin-bottom: 3%"
            v-model="selected_iiml_spoc"
            :options="iiml_spocs"
            label="full_name_team"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row type="flex" style="margin-top: 0%">
        <vs-col
          vs-order="1"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-button
            color="dark"
            type="gradient"
            size="medium"
            style="margin-top: 3%"
            @click="assignLead"
            >Assign</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import eventbus from "../eventbus";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  mounted() {
    eventbus.$on("open-assign-net-enquiry-popup", (payload, payload2) => {
      this.selected_team = "CM Team";
      this.getExed();
      this.payload = "";
      this.payload2 = "";
      this.rawBde = payload2;
      this.EnquirySpelling = "Enquiries";
      this.checkedEnquiries = payload;
      // console.log("dsfhuds", this.checkedEnquiries);
      if (this.checkedEnquiries.length === 1) {
        this.EnquirySpelling = "Enquiry";
      }
      this.spocscopy = [];
      this.cities = [];
      this.spocs = [];
      // this.getSpocs();
      this.buildString(payload);
      this.categorizeSpocs();
      this.open = true;
    });
  },
  data() {
    return {
      spocscopy: [],
      cities: [],
      rawBde: [],
      spocs: [],
      selected_spoc: "",
      selected_city: "",
      selected_team: "",
      selected_iiml_spoc: "",
      iiml_spocs: [],
      cityString: "",
      EnquirySpelling: "Enquiries",
      checkedEnquiries: [],
      open: false,
    };
  },
  components: {
    "v-select": vSelect,
  },
  watch: {
    selected_city: function(value) {
      this.spocs = [];
      console.log(this.spocscopy);
      this.spocscopy.forEach((spoc) => {
        if (spoc.city === value.label && spoc.net_enquiry_assignment == 1) {
          this.spocs.push(spoc);
        }
      });
      this.selected_spoc = this.spocs[0];
    },
  },
  methods: {
    /* getSpocs() {
      axios
        .get(`${constants.SERVER_API}getSpocs`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.cities = [];
          var unique = response.data.spocs
            .map(name => {
              return {
                count: 1,
                name: name.city
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.cities.push({
                label: key,
                value: key
              });
            }
          }
          for (var i = 0; i < response.data.spocs.length; i++) {
            var option = {
              label: response.data.spocs[i].first_name,
              value: response.data.spocs[i].id,
              city: response.data.spocs[i].city
            };
            this.spocscopy.push(option);
          }
          this.selected_city = this.cities[0];
        })
        .catch(error => {
          this.handleError(error);
        });
    }, */
    buildString(payload) {
      console.log(payload);
      let cities = [];
      payload.forEach((enquiry) => {
        cities.push(enquiry.city);
        cities.push(enquiry.course);
      });
      // let cities = [];
      // var unique = payload
      //   .map((name) => {
      //     return {
      //       count: 1,
      //       name: name.city,
      //     };
      //   })
      //   .reduce((a, b) => {
      //     a[b.name] = (a[b.name] || 0) + b.count;
      //     return a;
      //   }, {});
      // for (var key in unique) {
      //   if (unique.hasOwnProperty(key)) {
      //     cities.push(key);
      //   }
      // }

      this.cityString = cities.join();
    },
    categorizeSpocs() {
      this.cities = [];
      var unique = this.rawBde
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push({
            label: key,
            value: key,
          });
        }
      }
      this.cities = this.sortNestedArrayAlphabetically(this.cities);
      for (var i = 0; i < this.rawBde.length; i++) {
        var option = {
          label: this.rawBde[i].first_name,
          value: this.rawBde[i].id,
          city: this.rawBde[i].city,
          net_enquiry_assignment: this.rawBde[i].assign_net_enquiry,
        };
        this.spocscopy.push(option);
      }
      this.selected_city = this.cities[0];
    },
    getExed() {
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.iiml_spocs = [];
          response.data.spocs.forEach((spoc) => {
            spoc.full_name_team = `${spoc.full_name} - ${spoc.sub_team}`
            if (spoc.assign_net_enquiry === 1) {
              this.iiml_spocs.push(spoc)
            }
          })
          // this.generateCityList(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    assignLead() {
      var mappedEnquiries = this.checkedEnquiries.map((enquiry) => enquiry.id);
      let obj = {
        spoc_id: "",
        net_enquiry_ids: mappedEnquiries.join(),
      };

      let url = "";
      if (this.selected_team === "CM Team") {
        url = `${constants.SERVER_API}assignNetEnquiryToSpoc`;
        obj.spoc_id = this.selected_spoc.value;
      } else if (this.selected_team === "IIML Team") {
        url = `${constants.SERVER_API}assignNetEnquiryToIIMLSpoc`;
        obj.spoc_id = this.selected_iiml_spoc.id;
      }

      console.log(obj);
      console.log(url);
      // return;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            eventbus.$emit("reload-netEnquiries-cm2");
            this.open = false;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
