<template>
  <div>
    <!-- <vs-row type="flex" style="margin-top: 1%">
      <vs-col vs-order="1" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <v-select
          v-model="filter"
          label="name"
          :reduce="option => option.value"
          :options="filters"
          style="width: 250px; z-index: 1000"
        ></v-select>
      </vs-col>
      <vs-col vs-order="1" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <treeselect
          v-model="initSelectedBDE"
          :multiple="true"
          :options="treeDataBDE"
          style="width: 240px; margin-right: 3%"
        />
      </vs-col>
      <vs-col vs-order="2" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <date-range-picker
          ref="picker"
          :opens="'right'"
          :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY', customRangeLabel: 'Custom Range' }"
          :minDate="null"
          :maxDate="null"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          v-model="dateRange"
          style="width: 250px"
          @update="analyzePagination(1)"
        ></date-range-picker>
      </vs-col>
      <vs-col vs-order="3" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
        <vs-button class="gap" color="dark" type="gradient" icon="refresh" @click="refreshnew"></vs-button>
      </vs-col>
    </vs-row>-->
    <vs-row type="flex" style="margin-top: 1%">
      <vs-col
        vs-order="1"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-chip color="primary">
          <b>{{ total }}</b>
        </vs-chip>
      </vs-col>
    </vs-row>
    <vs-row type="flex" style="margin-top: 1%">
      <vs-col
        vs-order="1"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-button
          color="dark"
          type="gradient"
          size="small"
          v-if="checked.length != 0"
          @click="assignLead"
          >Assign</vs-button
        >
        <vs-button
          color="dark"
          type="gradient"
          size="small"
          style="margin-left:2%"
          v-if="checked.length != 0"
          @click="checked = []"
          >Deselect all</vs-button
        >
        <vs-button
          color="dark"
          type="gradient"
          size="small"
          style="margin-left:2%"
          v-if="checked.length != 0 && this.label !== 'international'"
          @click="markAsInternational()"
          >Mark as International Enquiry</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-table max-items="10" :data="NetEnquiries" style="margin-top: 1%">
      <template slot="thead">
        <vs-th>Assign</vs-th>
        <vs-th>Enquiry Date</vs-th>
        <vs-th>Name</vs-th>
        <vs-th>City</vs-th>
        <vs-th>Suggested City</vs-th>
        <vs-th>Mobile</vs-th>
        <vs-th>Email</vs-th>
        <vs-th>Course</vs-th>
        <vs-th>SPOC</vs-th>
        <vs-th>IIML SPOC</vs-th>
        <vs-th> Can-ID</vs-th>
        <!-- <vs-th>Person Name</vs-th> -->
        <vs-th>Person Type</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Matched</vs-th>
        <vs-th v-if="role === 'cm1'">Actions</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <input
                v-if="tr.selected_spoc === null"
                type="checkbox"
                :value="tr"
                id="checkbox"
                v-model="checked"
              />
            </vs-td>

            <vs-td>
              <div>{{ tr.enquiry_date }}</div>
            </vs-td>

            <vs-td>
              <vs-col
                v-if="isChat(tr.details)"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <vx-tooltip :text="'Click to view chat'">
                  <a @click="ViewChat(tr)">{{ tr.name }}</a>
                </vx-tooltip>
              </vs-col>
              <vs-col
                v-else
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <vx-tooltip :title="'Details'" :text="tr.details">
                  {{ tr.name }}
                </vx-tooltip>
              </vs-col>
            </vs-td>

            <vs-td>
              <div>{{ tr.city }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.suggested_city }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.mobile }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.email }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.course }}</div>
            </vs-td>

            <vs-td>
              <div>{{ getSpocName(tr.selected_spoc) }}</div>
            </vs-td>

            <vs-td>
              <div>{{ getIIMLName(tr.selected_iiml_spoc) }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.identity }}</div>
            </vs-td>

            <!-- <vs-td>
              <div>{{ tr.person_name }}</div>
            </vs-td>-->

            <vs-td>
              <div>{{ tr.person_type }}</div>
            </vs-td>

            <vs-td>
              <div>{{ sortText(tr.status) }}</div>
            </vs-td>

            <vs-td>
              <div>{{ getmatched(tr.matched, tr) }}</div>
            </vs-td>

            <vs-td v-if="role === 'cm1'">
              <vs-row>
                <div>
                  <img
                    v-if="tr.matched === 1"
                    @click="openPopupEmit(tr)"
                    src="https://img.icons8.com/material-outlined/20/000000/info.png"
                  />
                  <img
                    v-if="tr.matched !== 1 && tr.invalid_net_enquiry !== 1"
                    @click="searchLead(tr)"
                    src="https://img.icons8.com/ios/20/000000/search--v1.png"
                  />
                  <img
                    v-if="tr.matched !== 1 && tr.invalid_net_enquiry !== 1"
                    @click="addLead(tr)"
                    src="https://img.icons8.com/windows/20/000000/plus.png"
                  />
                  <img
                    v-if="tr.mobile !== ''"
                    @click="calluntracked(tr)"
                    src="https://img.icons8.com/android/20/000000/phone.png"
                  />

                  <img
                    v-if="tr.email !== ''"
                    @click="EmitEvent('open-email-engagement', tr)"
                    src="https://img.icons8.com/windows/20/000000/gmail.png"
                  />
                  <img
                    v-if="tr.matched !== 1 && tr.invalid_net_enquiry == 0"
                    @click="EmitEvent('mark-irrelevant-net-enquiry', tr)"
                    src="https://img.icons8.com/ios-glyphs/20/000000/exclamation-mark.png"
                  />
                  <svg
                    v-if="tr.matched !== 1 && tr.invalid_net_enquiry == 1"
                    @click="EmitEvent('mark-irrelevant-net-enquiry', tr)"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 171 171"
                    style=" fill:#000000;"
                  >
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="1"
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dasharray
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <path
                        d="M0,171.99654v-171.99654h171.99654v171.99654z"
                        fill="none"
                      />
                      <g fill="#e74c3c">
                        <path
                          d="M70.42617,17.1l2.44922,91.2h25.16016l2.53828,-91.2zM85.54453,125.4c-9.36509,0 -15.20742,5.43673 -15.20742,14.29453c0,8.77801 5.84802,14.20547 15.20742,14.20547c9.3594,0 15.12949,-5.42747 15.12949,-14.20547c0,-8.85779 -5.76439,-14.29453 -15.12949,-14.29453z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </vs-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <!-- <vs-pagination style="margin-top: 2%" :total="totalpagination" v-model="currentx"></vs-pagination> -->
    <!-- <NetEnquiryAssignmentPopup></NetEnquiryAssignmentPopup> -->
    <NetEnquiriesChatPopup></NetEnquiriesChatPopup>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus.js";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import NetEnquiryAssignmentPopup from "../components/pagesComponents/NetEnquiryAssignmentPopup.vue";
import NetEnquiriesChatPopup from "../components/pagesComponents/NetEnquiriesChatPopup.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import eventbus from "../components/eventbus.js";

export default {
  props: {
    NetEnquiries: {
      required: true,
      type: Array,
    },
    rawBde: {
      required: true,
      type: Array,
    },
    total: {
      required: true,
      type: Number,
    },
    label: {
      required: false,
      type: String,
    },
  },
  mounted() {
    this.getexed();
    // this.getexed2();
  },
  components: {
    // NetEnquiryAssignmentPopup,
    "v-select": vSelect,
    DateRangePicker,
    Treeselect,
    NetEnquiriesChatPopup,
  },
  data() {
    return {
      open: false,
      checked: [],
      dateRange: {},
      searchIn: {
        id: "",
        name: "",
      },
      totalpagination: 1,
      currentx: 1,
      initSelectedBDE: [],
      rawcities: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      netEnquiries: [],
      filter: "not_assigned",
      filters: [
        { name: "All", value: "all" },
        { name: "Assigned", value: "assigned" },
        { name: "Unassigned", value: "not_assigned" },
        { name: "Missed Hot Pocket", value: "missed_hot_pocket" },
        { name: "Connected", value: "connected" },
        { name: "Matched", value: "matched" },
        { name: "Unmatched", value: "unmatched" },
        { name: "Invalid", value: "invalid_net_enquiry" },
        { name: "Spam", value: "spam" },
      ],
      clickedEnquiry: {},
      spoc_names: [],
      exed: [],
      exed2: [],
      role: localStorage.getItem("role"),
    };
  },
  watch: {
    currentx: function(value) {
      this.fetchData(value);
    },
    filter() {
      this.analyzePagination(1);
    },
    initSelectedBDE() {
      this.analyzePagination(1);
    },
  },
  methods: {
    markAsInternational() {
      console.log(this.checked);
      let ids = [];
      this.checked.forEach((net_enquiry) => {
        ids.push(net_enquiry.id);
      });
      ids = ids.join();

      let obj = {
        net_enquiry_ids: ids,
      };
      axios
        .post(constants.SERVER_API + "markNetEnquiriesAsInternational", obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.checked = [];
            eventbus.$emit("updateCompleteNetEnquiry");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    isChat(details) {
      try {
        JSON.parse(details);
      } catch (e) {
        return false;
      }
      return true;
      // if (Array.isArray(JSON.parse(details))) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    analyzePagination() {
      if (this.currentx === 1) {
        this.fetchData(1);
      } else {
        this.currentx = 1;
      }
    },
    assignLead() {
      EventBus.$emit(
        "open-assign-net-enquiry-popup",
        this.checked,
        this.rawBde
      );
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    calluntracked(tr) {
      console.log(tr);
      let params = {};
      params.phone_number = parseInt(tr.mobile);
      axios
        .get(constants.SERVER_API + "clickToCallUntracked", {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.notify({
            title: "Call Initialized",
            text: response.data.message,
            color: "success",
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    sortText(givenName) {
      switch (givenName) {
        case "missed_hot_pocket":
          return "MHP";
        case "connected":
          return "Connected";
        default:
          return givenName;
      }
    },
    ViewChat(tr) {
      EventBus.$emit("open-net-enquiries-chat-popup", tr);
    },
    getSpocName(id) {
      let name = "";
      this.rawBde.forEach((spoc) => {
        if (spoc.id === id) {
          name = spoc.first_name;
        }
      });
      return name;
    },
    getIIMLName(id) {
      let name = "";
      this.exed.forEach((spoc) => {
        if (spoc.id === id) {
          name = spoc.first_name;
        }
      });
      // this.exed2.forEach(spoc => {
      //   if (spoc.id === id) {
      //     name = spoc.first_name;
      //   }
      // });
      return name;
    },
    // fetchspocs() {
    //   let url = `${constants.SERVER_API}getSpocs`;
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
    //     })
    //     .then(response => {
    //       this.spoc_names = response.data.spocs;
    //     })
    //     .catch(error => {
    //       this.handleError(error);
    //     });
    // },
    getexed() {
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.exed = response.data.spocs;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getexed2() {
      let url = `${constants.SERVER_API}getExed2`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.exed2 = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocAdmin() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.first_name,
                    label: server_spoc.first_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          } else {
            // this.initSelectedBDE.push("All");
          }
          this.fetchData(this.currentx);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    updateNetEnquiry(payload) {
      let obj = {
        net_enquiry_id: this.clickedEnquiry.id,
        person_id: this.clickedEnquiry.person_id,
        person_name: this.clickedEnquiry.person_name,
        identity: this.clickedEnquiry.identity,
        mwb_id: this.clickedEnquiry.mwb_id,
        person_type: this.clickedEnquiry.person_type,
        connection_status: payload.connection_status,
      };
      axios
        .post(`${constants.SERVER_API}updateNetEnquiry`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then(() => {
          this.fetchData(this.currentx);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getmatched(matched_value, tr) {
      console.log(tr);
      if (matched_value === 1) {
        return "YES";
      } else {
        return "NO";
      }
    },
    fetchData(page) {
      let obj = {
        page: page,
        // all, assigned, not_assigned
        filter: this.filter,
        spoc_ids: "",
        from_date: "",
        to_date: "",
      };
      let bde = [];
      this.initSelectedBDE.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              bde.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.treeDataBDE[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            bde.push(sort);
          }
        }
      });
      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      obj.spoc_ids = bdeid.join();
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }
      // console.log(this.dateRange);
      console.log(obj);
      axios
        .get(`${constants.SERVER_API}${this.url}`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          this.netEnquiries = response.data.data;
          this.totalpagination = response.data.last_page;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    refreshnew() {
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      this.filter = "not_assigned";
      this.initSelectedBDE = [];
      this.analyzePagination(1);
    },
    openPopupEmit(details) {
      console.log(details);
      this.clickedEnquiry = details;
      this.$vs.loading();
      axios
        .get(`${constants.SERVER_API}getMwbLead?mwb_id=${details.mwb_id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          response.data.data.engagements = [];
          response.data.data.mobile = [];
          response.data.data.emails = [];
          this.$store.commit("MOUNT_MWB_LEADS", [response.data.data]);
          axios
            .get(
              `${constants.SERVER_API}getSingleMwbLead?can_id=${
                response.data.data.can_id
              }&person_id=${response.data.data.person_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              }
            )
            .then((response2) => {
              this.$store.commit("UPDATE_NEW_DATA", [
                response.data.data,
                response2.data.engagements,
                response2.data.mobile,
                response2.data.emails,
              ]);
              EventBus.$emit(
                "open-student-details-popup",
                this.$store.getters.getObjectMwbLead(response.data.data)
              );
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.handleError(error);
            });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    searchLead(tr) {
      this.clickedEnquiry = tr;
      EventBus.$emit("search-net-enquiry-lead", tr);
    },
    addLead(tr) {
      this.clickedEnquiry = tr;
      EventBus.$emit("open-add-lead-popup");
      EventBus.$emit("prefilled-netEnquiry", tr);
    },
  },
  beforeDestroy() {
    EventBus.$emit("unloadplus", false);
  },
};
</script>

<style></style>
